// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ` html,
 body {
   margin: 0;
   padding: 0;
   width: 100%;
 }`, "",{"version":3,"sources":["webpack://./frontend/utils/global.module.css"],"names":[],"mappings":"CAAC;;GAEE,SAAS;GACT,UAAU;GACV,WAAW;CACb","sourcesContent":[" html,\n body {\n   margin: 0;\n   padding: 0;\n   width: 100%;\n }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
