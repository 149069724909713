// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.index-module__sessionsPage___MhakK {
  display: flex;
  flex-direction: row;
}

.index-module__imgContainer___UdLt_ {
  width: 55%;
}

.index-module__img___hqVFa {
  width: 100%; 
}

.index-module__sessionsFormContainer___s8mr3 {
  width: 45%;
  background: #fffcf7;
}

.index-module__sessionForm___O6Rkv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5%;
  font-family: "Poppins",
    "Poppins SC",
    sans-serif;
  color: #015669;
}

.index-module__sessionForm___O6Rkv h1 {
  font-size: 5vh;
}

.index-module__sessionForm___O6Rkv div {
  margin-top: 5%;
  width: 74%;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 8vh;
}

.index-module__sessionForm___O6Rkv input {
  width: 61%;
  padding: 10px;
  margin: 5px 0;
  font-size: 2.6vh;
  border-radius: 25px;
  border: 2px solid #015669;
  background: #fffcf7;
  height: 3vh;
}

.index-module__sessionButton___MhXuR {
  margin-top: 9%;
  background-color: rgb(43 148 179);
  color: #fffcf7;
  text-align: center;
  text-decoration: none;
  border-radius: 30px;
  border: none;
  cursor: pointer;
  font-size: 2.6vh;
  letter-spacing: .5px;
  font-weight: bolder;
  font-family: "Poppins",
    "Poppins SC",
    sans-serif;
  height: 6.5vh;
  width: 50%;
}

.index-module__sessionButton___MhXuR:hover {
  background-color: rgb(86, 170, 196);
}



`, "",{"version":3,"sources":["webpack://./frontend/features/sessions/index.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,UAAU;EACV,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,cAAc;EACd;;cAEY;EACZ,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;EACd,UAAU;EACV,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,UAAU;EACV,aAAa;EACb,aAAa;EACb,gBAAgB;EAChB,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,cAAc;EACd,iCAAiC;EACjC,cAAc;EACd,kBAAkB;EAClB,qBAAqB;EACrB,mBAAmB;EACnB,YAAY;EACZ,eAAe;EACf,gBAAgB;EAChB,oBAAoB;EACpB,mBAAmB;EACnB;;cAEY;EACZ,aAAa;EACb,UAAU;AACZ;;AAEA;EACE,mCAAmC;AACrC","sourcesContent":[".sessionsPage {\n  display: flex;\n  flex-direction: row;\n}\n\n.imgContainer {\n  width: 55%;\n}\n\n.img {\n  width: 100%; \n}\n\n.sessionsFormContainer {\n  width: 45%;\n  background: #fffcf7;\n}\n\n.sessionForm {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  margin-top: 5%;\n  font-family: \"Poppins\",\n    \"Poppins SC\",\n    sans-serif;\n  color: #015669;\n}\n\n.sessionForm h1 {\n  font-size: 5vh;\n}\n\n.sessionForm div {\n  margin-top: 5%;\n  width: 74%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 8vh;\n}\n\n.sessionForm input {\n  width: 61%;\n  padding: 10px;\n  margin: 5px 0;\n  font-size: 2.6vh;\n  border-radius: 25px;\n  border: 2px solid #015669;\n  background: #fffcf7;\n  height: 3vh;\n}\n\n.sessionButton {\n  margin-top: 9%;\n  background-color: rgb(43 148 179);\n  color: #fffcf7;\n  text-align: center;\n  text-decoration: none;\n  border-radius: 30px;\n  border: none;\n  cursor: pointer;\n  font-size: 2.6vh;\n  letter-spacing: .5px;\n  font-weight: bolder;\n  font-family: \"Poppins\",\n    \"Poppins SC\",\n    sans-serif;\n  height: 6.5vh;\n  width: 50%;\n}\n\n.sessionButton:hover {\n  background-color: rgb(86, 170, 196);\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sessionsPage": `index-module__sessionsPage___MhakK`,
	"imgContainer": `index-module__imgContainer___UdLt_`,
	"img": `index-module__img___hqVFa`,
	"sessionsFormContainer": `index-module__sessionsFormContainer___s8mr3`,
	"sessionForm": `index-module__sessionForm___O6Rkv`,
	"sessionButton": `index-module__sessionButton___MhXuR`
};
export default ___CSS_LOADER_EXPORT___;
